/*
color : 
green 00a875
white f1f2f2
black 18212a
green dark 50b74d
*/


body {
  direction: rtl;
  font-family: "Cairo", sans-serif;

}

body.light {
  background: #f1f2f2;
  color: #18212a;
}
body.dark {
  background: #18212a;
  color: #f1f2f2;
}

.heroSection {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./Images/heroSection/WhatsApp\ Image\ 2024-08-29\ at\ 1.52.36\ PM.webp);
}

.HeroSection {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./Images/heroSection/WhatsApp\ Image\ 2024-08-29\ at\ 1.52.36\ PM.webp);
  background-position: center;
  background-size: cover;
  position: relative;
}

input::placeholder {
  color: #18212a;
  padding-right: 12px;
}

.pepe {
  animation-name: pepe;
  animation-iteration-count: infinite;
}

@keyframes pepe {

  from,
  33%,
  66%,
  to {
    transform: rotate(4deg);
  }

  16%,
  50%,
  83% {
    transform: rotate(-4deg);
  }
}

.hu__hu__ {
  animation: hu__hu__ infinite 2s ease-in-out;
}

@keyframes hu__hu__ {
  50% {
    transform: translateY(10px);
  }
}



/* Loading Page */
.loader {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(closest-side at calc(100% / 6) 50%,
      #50b74d 90%,
      #0000) 0/75% 100%;
  position: relative;
  animation: l15-0 1s infinite linear;
}

.loader::before {
  content: "";
  position: absolute;
  background: inherit;
  clip-path: inset(0 0 0 50%);
  inset: 0;
  animation: l15-1 0.5s infinite linear;
}

@keyframes l15-0 {

  0%,
  49.99% {
    transform: scale(1);
  }

  50%,
  100% {
    transform: scale(-1);
  }
}

@keyframes l15-1 {
  0% {
    transform: translateX(-37.5%) rotate(0turn);
  }

  80%,
  100% {
    transform: translateX(-37.5%) rotate(1turn);
  }
}